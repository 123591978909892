import React from 'react';
import styled, { css } from 'styled-components';
import theme from "./theme";

import Button from './Button';

const Component = styled.div`
  background: #171f46cc;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  visibility: ${props => props.shown ? 'visible' : 'hidden'};
  opacity: ${props => props.shown ? '1' : '0'};
  transform: ${props => props.shown ? 'translate(0, 0)' : 'translate(0, -15px)'};
  transition: ${props => props.shown ? 'transform 0.2s ease-in-out 0s, opacity 0.15s linear 0s, visibility 0s linear 0s' : 'transform 0.2s ease-in-out 0s, opacity 0.15s linear 0s, visibility 0s linear 0.2s'};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Inner = styled.div`
  background: white;
  padding: 30px;
  max-width: 480px;
  border-radius: 4px;
  box-shadow: 0px 0px 50px 0px #fcd5bd73;

  @media (max-width: 400px) {
    padding-bottom: 18px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

const Br = styled.br`
  @media (max-width: 400px) {
    display: none;
  }
`;

const ButtonStyles = css`
  width: 46%;

  @media (max-width: 400px) {
    margin: 8px 0;
    width: 200px;
  }
`;

const ConfirmButton = styled(Button)`
  ${ButtonStyles};
`;

const CancelButton = styled(Button)`
  ${ButtonStyles};
  color: ${theme.color.bright};
  background: white;
  border: 2px solid ${theme.color.bright};
`;

const ModalReward = ({ shown, className, onCancel, onConfirm }) => (
  <Component className={className} shown={shown}>
    <Inner>
      <Text>
        By Clicking Download Button You’ll <Br/>
        See Rewarded Ad
      </Text>
      <Buttons>
        <CancelButton onClick={onCancel}>Cancel</CancelButton>
        <ConfirmButton onClick={onConfirm}>Continue</ConfirmButton>
      </Buttons>
    </Inner>
  </Component>
);

export default ModalReward;
